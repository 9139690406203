import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './LanguageSwitcher.css';
import flagEN from '../../images/flags/flag-en.svg';
import flagDE from '../../images/flags/flag-de.svg';
import flagIT from '../../images/flags/flag-it.svg';
import flagNL from '../../images/flags/flag-nl.svg';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import i18n from '../../i18n'; // Adjust the path as necessary

class LanguageSwitcher extends Component {
  state = {
    menuOpen: false,
    menuStyle: {},
    currentFlag: flagEN // Default to English, or get from i18n if available
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('load', () => { this.adjustMenuPosition(); });
  
    // Set the current flag based on the current language
    this.setState({ currentFlag: this.getCurrentFlag(i18n.language) });
  }
  
  getCurrentFlag = (language) => {
    switch (language) {
      case 'en':
        return flagEN;
      case 'de':
        return flagDE;
      case 'it':
        return flagIT;
      case 'nl':
        return flagNL;
      default:
        return flagEN;
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('load', () => { this.adjustMenuPosition(); });
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ menuOpen: false });
    }
  };

  toggleMenu = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen
    }), () => {
      if (this.state.menuOpen) {
        this.adjustMenuPosition();
      }
    });
  };

  adjustMenuPosition = () => {
    const translateIconRect = this.wrapperRef.getBoundingClientRect();
    const dropdownMenu = this.wrapperRef.querySelector('.dropdown-menu');
    const dropdownRect = dropdownMenu.getBoundingClientRect();
    
    const menuStyle = { ...this.state.menuStyle }; // Copy current styles

    // Check if the dropdown menu would go out of the viewport
    if (translateIconRect.left + dropdownRect.width > window.innerWidth) {
      menuStyle.right = 0;
      menuStyle.left = 'auto';
      menuStyle.transform = 'translateX(0)';
    } else { // Reset to default if there's enough space
      menuStyle.left = '50%';
      menuStyle.right = 'auto';
      menuStyle.transform = 'translateX(-50%)';
    }

    this.setState({ menuStyle });
  };

  changeLanguage = (language) => {
    let newFlag;
    switch (language) {
      case 'en':
        newFlag = flagEN;
        break;
      case 'de':
        newFlag = flagDE;
        break;
      case 'it':
        newFlag = flagIT;
        break;
      case 'nl':
        newFlag = flagNL;
        break;
      default:
        newFlag = flagEN;
    }

    this.setState({ currentFlag: newFlag });
    i18n.changeLanguage(language);
    this.toggleMenu();
  };

  render() {
    const { menuOpen, menuStyle, currentFlag } = this.state;
    return (
      <div className="language-switcher" ref={this.setWrapperRef}>
        <div onClick={this.toggleMenu} className="translate-icon">
          <img src={currentFlag} alt="Current Language" className='current-flag'/>
          <KeyboardArrowDownRoundedIcon className='dropdown-arrow'/>
        </div>
        <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`} style={menuStyle}>
            <button onClick={() => this.changeLanguage('en')}><img src={flagEN} alt="English" /></button>
            <button onClick={() => this.changeLanguage('de')}><img src={flagDE} alt="Deutsch" /></button>
            <button onClick={() => this.changeLanguage('it')}><img src={flagIT} alt="Italiano" /></button>
            <button onClick={() => this.changeLanguage('nl')}><img src={flagNL} alt="Nederlands" /></button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LanguageSwitcher);
