import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "We're getting married": "We're getting married",
          "Welcome to the app": "Welcome to the app",
          "deselect": "De-select",
          "success-registry": "Thank you very much!\nYour selection has been submitted successfully!",
          "select": "Select",
          "l-taxi": "Transportation",
          "l-diet": "Dietary restrictions",
          "b-add-person": "Add guest",
          "RSVP": "RSVP",
          "Travel": "Travel",
          "Accomodation": "Accomodation",
          "Programme": "Programme",
          "To do tips": "To do tips",
          "Contact": "Contact",
          "Attendance": "Attendance",
          "I'm attending": "Count me in!",
          "I'm not attending": "I'm not attending",
          "Notes": "Notes",
          "Submit": "Submit",
          "d-no-gifts": "No selection. To select, click on any of the cards above.",
          "Cancel": "Cancel",
          "Add": "Add",
          "Wedding Registry": "Wedding Registry",
          "Name": "Name",
          "Email": "Email",
          "Message": "Message",
          "Name and Family Name": "Name and Family Name",
          "Email or Phone Number": "Email or Phone Number",
          "Dietary restrictions, I need help with the transport or other notes": "Dietary restrictions, I need help with the transport or other notes",
          "We're sorry, but we couldn't find your invitation.": "We're sorry, but we couldn't find your invitation.",
          "Please try again or contact us.": "Please try again or contact us.",
          "RSVP Submitted Successfully!": "RSVP Submitted Successfully!",
          "Open": "Open",
          // Hotels: 
          // La Ferme Ladouceur
          "Nestled near Ramatuelle and Pampelonne Beach, this charming bastide offers a retreat amidst Provence's vineyards.": "Nestled near Ramatuelle and Pampelonne Beach, this charming bastide offers a retreat amidst Provence's vineyards.",
          "From 140 EUR per room, per night": "From 140 EUR per room, per night",
          // Kon Tiki Ramatuelle
          "Beachfront experience on Pampelonne, near Saint Tropez, with unique Tiki Huttes and vibrant beach restaurants nearby.": "Beachfront experience on Pampelonne, near Saint Tropez, with unique Tiki Huttes and vibrant beach restaurants nearby.",
          "From 150 EUR per room, per night": "From 150 EUR per room, per night",
          // Toison D'or
          "Waterfront resort near Saint Tropez, ideal for outdoor activities, close to Grimaud Castle and St. Tropez Harbor.": "Waterfront resort near Saint Tropez, ideal for outdoor activities, close to Grimaud Castle and St. Tropez Harbor.",
          // L'Ecurie du Castellas
          "Hillside hotel in Ramatuelle with en-suite rooms, private terraces, and panoramic views, perfect for countryside exploration.": "Hillside hotel in Ramatuelle with en-suite rooms, private terraces, and panoramic views, perfect for countryside exploration.",
          "From 120 EUR per room, per night": "From 120 EUR per room, per night",
          // La Romarine
          "Nestled between Saint-Tropez and Pampelonne Beaches, offering bedrooms and villas in a lush park and a pool.": "Nestled between Saint-Tropez and Pampelonne Beaches, offering bedrooms and villas in a lush park and a pool.",
          // Hôtel Les Bouis
          "Peaceful retreat atop Ramatuelle hills, close to Pampelonne beaches, with rooms offering sea views, a garden, and a pool": "Peaceful retreat atop Ramatuelle hills, close to Pampelonne beaches, with rooms offering sea views, a garden, and a pool",
          "From 200 EUR per room, per night": "From 200 EUR per room, per night",
          // Résidence les sellettes
          "In the middle of a pine forest, a field of olive trees and vineyards. Minutes away from Pampelonne Beach and from Ramatuelle.": "In the middle of a pine forest, a field of olive trees and vineyards. Minutes away from Pampelonne Beach and from Ramatuelle.",
          "From 100 EUR per room, per night": "From 100 EUR per room, per night",
          // Ramatuelle Tourisme
          "Explore more stays! Check our tourism office list for a variety of local accommodation options.": "Explore more stays! Check our tourism office list for a variety of local accommodation options.",
          "": "",
          // AirBnB
          "Explore the diversity of Ramatuelle on Airbnb. Choose from a wide range of properties to suit every taste and budget.": "Explore the diversity of Ramatuelle on Airbnb. Choose from a wide range of properties to suit every taste and budget.",
          // Contact paragraph
          "If you have any questions, please don't hesitate to reach out to us using the form below, or for urgent matters, feel free to contact us directly. We look forward to hearing from you!": "If you have any questions, please don't hesitate to reach out to us using the form below, or for urgent matters, feel free to contact us directly. We look forward to hearing from you!",
          // Programme
          "Ceremony": "Ceremony",
          "Aperó": "Aperó",
          "Dinner": "Dinner",
          "After-Dinner": "After-Dinner",
          "End": "End",
          // To do tips
          // Saturday Morning Market in Saint-Tropez
          "Saturday Morning Market in Saint-Tropez": "Saturday Morning Market in Saint-Tropez",
          "2 hours": "2 hours",
          "Begin your day at the vibrant Saturday market in Saint-Tropez, exploring local crafts, foods, and souvenirs.": "Begin your day at the vibrant Saturday market in Saint-Tropez, exploring local crafts, foods, and souvenirs.",
          // Explore the Old Port of Saint-Tropez
          "Explore the Old Port of Saint-Tropez": "Explore the Old Port of Saint-Tropez",
          "1.5 hours": "1.5 hours",
          "After the market, wander around the Old Port, enjoying the cafes and charming atmosphere.": "After the market, wander around the Old Port, enjoying the cafes and charming atmosphere.",
          // Sunday Morning Market in Ramatuelle
          "Sunday Morning Market in Ramatuelle": "Sunday Morning Market in Ramatuelle",
          "1 hours": "1 hours",
          "Start your Sunday with the Ramatuelle market, known for its authentic local products and lively atmosphere.": "Start your Sunday with the Ramatuelle market, known for its authentic local products and lively atmosphere.",
          // Stroll through the Village of Ramatuelle
          "Stroll through the Village of Ramatuelle": "Stroll through the Village of Ramatuelle",
          "Explore the picturesque village of Ramatuelle, with its narrow streets and traditional houses.": "Explore the picturesque village of Ramatuelle, with its narrow streets and traditional houses.",
          // Relax on Pampelonne Beach, Ramatuelle
          "Relax on Pampelonne Beach, Ramatuelle": "Relax on Pampelonne Beach, Ramatuelle",
          "-": "-",
          "Spend your afternoon at Pampelonne Beach, famous for its clear waters and beach clubs.": "Spend your afternoon at Pampelonne Beach, famous for its clear waters and beach clubs.",
          // Hiking Escalet - Cap Taillat
          "Hiking Escalet - Cap Taillat": "Hiking Escalet - Cap Taillat",
          "5 hours": "5 hours",
          "Enjoy a scenic hike in Cap Taillat, discovering natural landscapes and quiet beaches.": "Enjoy a scenic hike in Cap Taillat, discovering natural landscapes and quiet beaches.",
          "b-transport": "RSVP",
          "t-spritz": "Aperol Spritz",
          "d-spritz": "A refreshing Aperol Spritz at a local bar for Aperitivo.",
          "t-gelato": "Gelato to cool off",
          "d-gelato": "Savor different flavors of gelato in Rome.",
          "t-espresso": "Espresso Tour",
          "d-espresso": "Explore and taste espresso from various cafes in Naples.",
          "t-pastry": "Pastry Tasting",
          "d-pastry": "Sample delicious pastries from different bakeries in Naples.",
          "t-limoncello": "Limoncello Tasting",
          "d-limoncello": "Taste traditional Limoncello in Amalfi.",
          "t-amalfi-bus": "Amalfi Coast Bus Tickets",
          "d-amalfi-bus": "Bus tickets to explore the scenic Amalfi Coast.",
          "t-vatican": "Musei Vaticani",
          "d-vatican": "Entry to the Vatican Museums and the Sistine Chapel.",
          "t-arnolfo": "Tower of Arnolfo",
          "d-arnolfo": "Entry to the tower of Arnolfo in Florence",
          "t-uffizi": "Galleria degli Uffizi",
          "d-uffizi": "Entry to the Uffizi Gallery in Florence",
          "t-augusto": "Giardinetti di Augusto",
          "d-augusto": "Entry to the Giardinetti di Augusto in Capri",
          "t-monte-solaro": "Monte Solaro",
          "d-monte-solaro": "Ticket fo the Monte Solaro chairlift in Anacapri",
          "t-ferry": "Contrinute to our ferry tickets",
          "d-ferry": "Contribute to our ferry tickets to explore the Islands near Rome and Naples",
          "t-train": "Contribute to out Train tickets",
          "d-train": "Contribute to our Train tickets to explore the cities of Italy",
          "t-sunset-prosecco": "Sunset Prosecco",
          "d-sunset-prosecco": "Enjoy a Prosecco toast at sunset in Rome.",
          "t-luxury-dinner": "Romantic Dinner in Rome",
          "d-luxury-dinner": "Contribute to a unique dinner in Rome.",
          "t-pizza-naples": "Pizza in Naples",
          "d-pizza-naples": "Going to Naples and eating a pizza is a must!",
          "t-boat-rental": "Boat Rental",
          "d-boat-rental": "Rent a boat to explore the island of Ponza.",
          "t-contribution": "Free Contribution",
          "d-contribution": "Contribution towards our honeymoon.",
          "t-selection": "Your Selection",
          "t-info": "Details",
          "t-dinner-capri": "Romantic Dinner in Capri",
          "d-dinner-capri": "Contribute to a romantic dinner in Capri.",
          "t-dinner-firenze": "Romantic Dinner in Firenze",
          "d-dinner-firenze": "Contribute to a romantic dinner in Firenze.",
          "t-vespa": "Rent a Vespa",
          "d-vespa": "Rent a Vespa to explore the coast.",
          "d-wedding-registry": "Dear guests,\nWe are delighted to welcome you to our wedding, aware of the commitment and journey many of you will undertake to be with us on this special day. Your presence is the most precious gift to us, and we wish for nothing more.\nHowever, if you feel the desire to celebrate with an additional gesture, we have thought of a way that can contribute to our honeymoon. In September, we will start our honeymoon journey by train, passing through cities like Florence, Rome, and Naples, and along the Amalfi Coast, before returning to home. We have prepared a list of ideas that might inspire you. If you decide to participate, please leave your name so we can thank you personally.\nThere are no expectations, just the wish to share the happiness of this moment with you. For those who wish to contribute, you can do so by selecting an option from the list or with a free contribution, which can be transferred to our joint account.\nThank you deeply for the love and support you have always shown us."
        }
      },
      de: {
        translation: {
          "We're getting married": "Wir heiraten",
          "Welcome to the app": "Willkommen in der App",
          "RSVP": "RSVP",
          "success-registry": "Vielen Dank!\nDeine Auswahl wurde erfolgreich übermittelt!",
          "b-transport": "RSVP",
          "Travel": "Reise",
          "l-taxi": "Transport",
          "l-diet": "Diätbeschränkungen",
          "b-add-person": "Gast hinzufügen",
          "Accomodation": "Hotels",
          "Programme": "Programm",
          "To do tips": "To do Tipps",
          "Contact": "Kontakt",
          "Name": "Name",
          "d-no-gifts": "Keine Auswahl. Um eine Auswahl zu treffen, klicken Sie auf eine der Karten oben.",
          "Email": "E-Mail",
          "Message": "Nachricht",
          "Attendance": "Teilnahme",
          "I'm attending": "Ich bin dabei!",
          "I'm not attending": "Ich bin nicht dabei",
          "Notes": "Notizen",
          "Submit": "Senden",
          "Cancel": "Abbrechen",
          "Add": "Hinzufügen",
          "Name and Family Name": "Name und Familienname",
          "Email or Phone Number": "E-Mail oder Telefonnummer",
          "Dietary restrictions, I need help with the transport or other notes": "Diätbeschränkungen, ich brauche Hilfe beim Transport oder andere Notizen",
          "We're sorry, but we couldn't find your invitation.": "Wir konnten Ihre Einladung leider nicht finden.",
          "Please try again or contact us.": "Bitte versuchen Sie es erneut oder kontaktieren Sie uns.",
          "RSVP Submitted Successfully!": "RSVP erfolgreich eingereicht!",
          "Open": "Öffnen",
          // Hotels: 
          // La Ferme Ladouceur
          "Nestled near Ramatuelle and Pampelonne Beach, this charming bastide offers a retreat amidst Provence's vineyards.": "In der Nähe von Ramatuelle und dem Strand von Pampelonne gelegen, bietet diese charmante Bastide einen Rückzugsort inmitten der Weinberge der Provence.",
          "From 140 EUR per room, per night": "Ab 140 EUR pro Zimmer, pro Nacht",
          // Kon Tiki Ramatuelle
          "Beachfront experience on Pampelonne, near Saint Tropez, with unique Tiki Huttes and vibrant beach restaurants nearby.": "Stranderlebnis in Pampelonne, in der Nähe von Saint Tropez, mit einzigartigen Tiki Hütten und lebhaften Strandrestaurants in der Nähe.",
          "From 150 EUR per room, per night": "Ab 150 EUR pro Zimmer, pro Nacht",
          // Toison D'or
          "Waterfront resort near Saint Tropez, ideal for outdoor activities, close to Grimaud Castle and St. Tropez Harbor.": "Strandresort in der Nähe von Saint Tropez, ideal für Outdoor-Aktivitäten, nahe dem Schloss Grimaud und dem Hafen von St. Tropez.",
          // L'Ecurie du Castellas
          "Hillside hotel in Ramatuelle with en-suite rooms, private terraces, and panoramic views, perfect for countryside exploration.": "Hanghotel in Ramatuelle mit Zimmern mit Bad, privaten Terrassen und Panoramablick, ideal zur Erkundung des Umlands.",
          "From 120 EUR per room, per night": "Ab 120 EUR pro Zimmer, pro Nacht",
          // La Romarine
          "Nestled between Saint-Tropez and Pampelonne Beaches, offering bedrooms and villas in a lush park and a pool.": "Zwischen den Stränden von Saint-Tropez und Pampelonne gelegen, bietet es Schlafzimmer und Villen in einem üppigen Park mit Pool.",
          // Hôtel Les Bouis
          "Peaceful retreat atop Ramatuelle hills, close to Pampelonne beaches, with rooms offering sea views, a garden, and a pool": "Ruhiger Rückzugsort auf den Hügeln von Ramatuelle, in der Nähe der Strände von Pampelonne, mit Zimmern mit Meerblick, Garten und Pool",
          "From 200 EUR per room, per night": "Ab 200 EUR pro Zimmer, pro Nacht",
          // Résidence les sellettes
          "In the middle of a pine forest, a field of olive trees and vineyards. Minutes away from Pampelonne Beach and from Ramatuelle.": "Mitten in einem Pinienwald, umgeben von einem Olivenhain und Weinbergen. Nur wenige Minuten vom Strand von Pampelonne und Ramatuelle entfernt.",
          "From 100 EUR per room, per night": "Ab 100 EUR pro Zimmer, pro Nacht",
          // Ramatuelle Tourisme
          "Explore more stays! Check our tourism office list for a variety of local accommodation options.": "Entdecken Sie weitere Unterkünfte! Sehen Sie sich unsere Tourismusbüro-Liste für eine Vielzahl lokaler Unterkunftsmöglichkeiten an.",
          "": "",
          // AirBnB
          "Explore the diversity of Ramatuelle on Airbnb. Choose from a wide range of properties to suit every taste and budget.": "Erkunden Sie die Vielfalt von Ramatuelle auf Airbnb. Wählen Sie aus einer Vielzahl von Unterkünften, die jedem Geschmack und Budget gerecht werden.",
          // Contact paragraph
          "If you have any questions, please don't hesitate to reach out to us using the form below, or for urgent matters, feel free to contact us directly. We look forward to hearing from you!": "Wenn du Fragen hast, zögere bitte nicht, uns über das untenstehende Formular zu kontaktieren, oder für dringende Angelegenheiten, kontaktiere uns gerne direkt. Wir freuen uns darauf, von dir zu hören!",
          // Programme
          "Ceremony": "Zeremonie",
          "Aperó": "Aperó",
          "Dinner": "Abendessen",
          "After-Dinner": "Nach dem Abendessen",
          "End": "Ende",
          // To do tips
          // Saturday Morning Market in Saint-Tropez
          "Saturday Morning Market in Saint-Tropez": "Samstag Morgen Markt in Saint-Tropez",
          "2 hours": "2 Stunden",
          "Begin your day at the vibrant Saturday market in Saint-Tropez, exploring local crafts, foods, and souvenirs.": "Beginnen Sie Ihren Tag auf dem lebhaften Samstagsmarkt in Saint-Tropez und erkunden Sie lokale Handwerkskunst, Lebensmittel und Souvenirs.",
          // Explore the Old Port of Saint-Tropez
          "Explore the Old Port of Saint-Tropez": "Erkunden Sie den alten Hafen von Saint-Tropez",
          "1.5 hours": "1,5 Stunden",
          "After the market, wander around the Old Port, enjoying the cafes and charming atmosphere.": "Nach dem Markt schlendern Sie durch den alten Hafen und genießen die Cafés und die charmante Atmosphäre.",
          // Sunday Morning Market in Ramatuelle
          "Sunday Morning Market in Ramatuelle": "Sonntag Morgen Markt in Ramatuelle",
          "1 hours": "1 Stunde",
          "Start your Sunday with the Ramatuelle market, known for its authentic local products and lively atmosphere.": "Beginnen Sie Ihren Sonntag mit dem Ramatuelle Markt, bekannt für seine authentischen lokalen Produkte und lebendige Atmosphäre.",
          // Stroll through the Village of Ramatuelle
          "Stroll through the Village of Ramatuelle": "Spazieren Sie durch das Dorf Ramatuelle",
          "Explore the picturesque village of Ramatuelle, with its narrow streets and traditional houses.": "Erkunden Sie das malerische Dorf Ramatuelle mit seinen engen Gassen und traditionellen Häusern.",
          // Relax on Pampelonne Beach, Ramatuelle
          "Relax on Pampelonne Beach, Ramatuelle": "Entspannen Sie sich am Strand von Pampelonne, Ramatuelle",
          "-": "-",
          "Spend your afternoon at Pampelonne Beach, famous for its clear waters and beach clubs.": "Verbringen Sie Ihren Nachmittag am Pampelonne Strand, berühmt für sein klares Wasser und die Strandclubs.",
          // Hiking Escalet - Cap Taillat
          "Hiking Escalet - Cap Taillat": "Wandern Escalet - Cap Taillat",
          "5 hours": "5 Stunden",
          "Enjoy a scenic hike in Cap Taillat, discovering natural landscapes and quiet beaches.": "Genießen Sie eine malerische Wanderung in Cap Taillat und entdecken Sie die natürliche Landschaft und die ruhigen Strände.",
          "Wedding Registry": "Hochzeitsliste",
          "deselect": "Abwählen",
          "select": "Auswählen",
          "t-spritz": "Aperol Spritz",
          "d-spritz": "Ein erfrischender Aperol Spritz in einer lokalen Bar zum Aperitivo.",
          "t-gelato": "Gelato zum Abkühlen",
          "d-gelato": "Genießen Sie verschiedene Geschmacksrichtungen von Gelato in Rom.",
          "t-espresso": "Espresso-Tour",
          "d-espresso": "Erkunden und probieren Sie Espresso aus verschiedenen Cafés in Neapel.",
          "t-pastry": "Gebäckverkostung",
          "d-pastry": "Probieren Sie köstliche Gebäckstücke aus verschiedenen Bäckereien in Neapel.",
          "t-limoncello": "Limoncello-Verkostung",
          "d-limoncello": "Probieren Sie traditionellen Limoncello in Amalfi.",
          "t-amalfi-bus": "Amalfiküsten-Busfahrkarten",
          "d-amalfi-bus": "Busfahrkarten, um die malerische Amalfiküste zu erkunden.",
          "t-vatican": "Vatikanische Museen",
          "d-vatican": "Eintritt in die Vatikanischen Museen und die Sixtinische Kapelle.",
          "t-arnolfo": "Turm von Arnolfo",
          "d-arnolfo": "Eintritt zum Turm von Arnolfo in Florenz",
          "t-uffizi": "Galleria degli Uffizi",
          "d-uffizi": "Eintritt in die Uffizien-Galerie in Florenz",
          "t-augusto": "Giardinetti di Augusto",
          "d-augusto": "Eintritt in die Giardinetti di Augusto in Capri",
          "t-monte-solaro": "Monte Solaro",
          "d-monte-solaro": "Ticket für den Monte Solaro-Sessellift in Anacapri",
          "t-ferry": "Beitrag zu unseren Fährkarten",
          "d-ferry": "Trag  zu unseren Fährkarten bei, um die Inseln in der Nähe von Rom und Neapel zu erkunden",
          "t-train": "Beitrag zu unseren Zugtickets",
          "d-train": "Trag  zu unseren Zugtickets bei, um die Städte Italiens zu erkunden",
          "t-sunset-prosecco": "Sonnenuntergang Prosecco",
          "d-sunset-prosecco": "Prosecco Anstoss bei Sonnenuntergang in Rom.",
          "t-luxury-dinner": "Romantisches Dinner in Rome",
          "d-luxury-dinner": "Trag  zu einem einzigartigen Abendessen in Rom bei.",
          "t-pizza-naples": "Pizza in Neapel",
          "d-pizza-naples": "Nach Neapel zu gehen und eine Pizza zu essen ist ein Muss!",
          "t-boat-rental": "Bootverleih",
          "d-boat-rental": "Mieten Sie ein Boot, um die Insel Ponza zu erkunden.",
          "t-contribution": "Freie Beitrag",
          "d-contribution": "Trag zu unserer Hochzeitsreise bei.",
          "t-selection": "Deine Auswahl",
          "t-info": "Details",
          "t-vespa": "Vespa mieten",
          "d-vespa": "Miete eine Vespa, um die Küste zu erkunden.",
          "t-dinner-capri": "Romantisches Dinner in Capri",
          "d-dinner-capri": "Trag zu einem romantischen Dinner in Capri bei.",
          "t-dinner-firenze": "Romantisches Dinner in Firenze",
          "d-dinner-firenze": "Trag zu einem romantischen Dinner in Firenze bei.",
          "d-wedding-registry": "Liebe Gäste,\nwir freuen uns sehr, euch auf unserer Hochzeit begrüßen zu dürfen, und sind uns des Engagements und der Reise bewusst, die viele von euch unternehmen werden, um an diesem besonderen Tag bei uns zu sein. Eure Anwesenheit ist für uns das kostbarste Geschenk, und wir wünschen uns nichts weiter.\nSolltet ihr jedoch den Wunsch verspüren, mit einer zusätzlichen Geste zu feiern, haben wir an eine Möglichkeit gedacht, die zu unserer Hochzeitsreise beitragen kann. Im September werden wir unsere Hochzeitsreise mit dem Zug beginnen, dabei Städte wie Florenz, Rom und Neapel sowie die Amalfiküste bereisen und schließlich nach Hause zurückkehren. Wir haben eine Liste mit Ideen vorbereitet, die euch inspirieren könnten. Wenn ihr euch entscheidet teilzunehmen, hinterlasst bitte euren Namen, damit wir euch persönlich danken können.\nEs gibt keine Erwartungen, nur den Wunsch, das Glück dieses Moments mit euch zu teilen. Für diejenigen, die beitragen möchten, könnt ihr dies tun, indem ihr eine Option aus der Liste auswählt oder einen freien Beitrag leistet, der auf unser gemeinsames Konto überwiesen werden kann.\nVielen Dank für die Liebe und Unterstützung, die ihr uns immer gezeigt habt."
        },
      },
      nl: {
        translation: {
          "We're getting married": "Wij gaan trouwen",
          "Welcome to the app": "Welkom bij de app",
          "RSVP": "RSVP",   
          "success-registry": "Hartelijk dank!\nJe selectie is succesvol ingediend!",
          "l-taxi": "Taxi",
          "l-diet": "Dieetbeperkingen",
          "b-add-person": "Gast toevoegen",
          "Accomodation": "Hotels",
          "Travel": "Reizen",
          "d-no-gifts": "Geen selectie. Om te selecteren, klik op een van de kaarten hierboven.",
          "Programme": "Programma",
          "To do tips": "To do tips",
          "Contact": "Contact",
          "Name": "Naam",
          "Email": "E-mail",
          "Message": "Bericht",
          "Attendance": "Aanwezigheid",
          "I'm attending": "Ik ben erbij!",
          "I'm not attending": "Ik ben er niet bij",
          "Notes": "Notities",
          "Submit": "Verzenden",
          "Cancel": "Annuleren",
          "Add": "Toevoegen",
          "Name and Family Name": "Naam en familienaam",
          "Email or Phone Number": "E-mail of telefoonnummer",
          "Dietary restrictions, I need help with the transport or other notes": "Dieetbeperkingen, ik heb hulp nodig bij het vervoer of andere notities",
          "We're sorry, but we couldn't find your invitation.": "We kunnen uw uitnodiging helaas niet vinden.",
          "Please try again or contact us.": "Probeer het opnieuw of neem contact met ons op",
          "RSVP Submitted Successfully!": "RSVP succesvol ingediend!",
          "Open": "Openen",
          // Hotels: 
          // La Ferme Ladouceur
          "Nestled near Ramatuelle and Pampelonne Beach, this charming bastide offers a retreat amidst Provence's vineyards.": "Gelegen nabij Ramatuelle en het strand van Pampelonne, biedt deze charmante bastide een toevluchtsoord te midden van de wijngaarden van de Provence.",
          "From 140 EUR per room, per night": "Vanaf 140 EUR per kamer, per nacht",
          // Kon Tiki Ramatuelle
          "Beachfront experience on Pampelonne, near Saint Tropez, with unique Tiki Huttes and vibrant beach restaurants nearby.": "Strandervaring in Pampelonne, in de buurt van Saint Tropez, met unieke Tiki Huttes en levendige strandrestaurants in de buurt.",
          "From 150 EUR per room, per night": "Vanaf 150 EUR per kamer, per nacht",
          // Toison D'or
          "Waterfront resort near Saint Tropez, ideal for outdoor activities, close to Grimaud Castle and St. Tropez Harbor.": "Resort aan het water in de buurt van Saint Tropez, ideaal voor buitenactiviteiten, dicht bij het kasteel van Grimaud en de haven van St. Tropez.",
          // L'Ecurie du Castellas
          "Hillside hotel in Ramatuelle with en-suite rooms, private terraces, and panoramic views, perfect for countryside exploration.": "Hotel op een heuvel in Ramatuelle met kamers met eigen badkamer, privéterrassen en panoramisch uitzicht, perfect voor het verkennen van het platteland.",
          "From 120 EUR per room, per night": "Vanaf 120 EUR per kamer, per nacht",
          // La Romarine
          "Nestled between Saint-Tropez and Pampelonne Beaches, offering bedrooms and villas in a lush park and a pool.": "Gelegen tussen de stranden van Saint-Tropez en Pampelonne, met slaapkamers en villa's in een weelderig park met een zwembad.",
          // Hôtel Les Bouis
          "Peaceful retreat atop Ramatuelle hills, close to Pampelonne beaches, with rooms offering sea views, a garden, and a pool": "Rustige toevlucht op de heuvels van Ramatuelle, dicht bij de stranden van Pampelonne, met kamers met uitzicht op zee, een tuin en een zwembad",
          "From 200 EUR per room, per night": "Vanaf 200 EUR per kamer, per nacht",
          // Résidence les sellettes
          "In the middle of a pine forest, a field of olive trees and vineyards. Minutes away from Pampelonne Beach and from Ramatuelle.": "Midden in een dennenbos, een veld met olijfbomen en wijngaarden. Op enkele minuten afstand van het strand van Pampelonne en Ramatuelle.",
          "From 100 EUR per room, per night": "Vanaf 100 EUR per kamer, per nacht",
          // Ramatuelle Tourisme
          "Explore more stays! Check our tourism office list for a variety of local accommodation options.": "Ontdek meer verblijven! Bekijk onze lijst met toeristenbureaus voor een verscheidenheid aan lokale accommodatiemogelijkheden.",
          "": "",
          // AirBnB
          "Explore the diversity of Ramatuelle on Airbnb. Choose from a wide range of properties to suit every taste and budget.": "Ontdek de diversiteit van Ramatuelle op Airbnb. Kies uit een breed scala aan accommodaties die passen bij elke smaak en elk budget.",
          // Contact paragraph
          "If you have any questions, please don't hesitate to reach out to us using the form below, or for urgent matters, feel free to contact us directly. We look forward to hearing from you!": "Als je vragen hebt, aarzel dan niet om contact met ons op te nemen via het onderstaande formulier, of neem voor dringende zaken gerust rechtstreeks contact met ons op. We kijken ernaar uit om van je te horen!",
          // Programme
          "Ceremony": "Ceremonie",
          "Aperó": "Aperó",
          "Dinner": "Diner",
          "After-Dinner": "After-Dinner",
          "End": "Einde",
          // To do tips
          // Saturday Morning Market in Saint-Tropez
          "Saturday Morning Market in Saint-Tropez": "Zaterdagochtendmarkt in Saint-Tropez",
          "2 hours": "2 uur",
          "Begin your day at the vibrant Saturday market in Saint-Tropez, exploring local crafts, foods, and souvenirs.": "Begin uw dag op de levendige zaterdagmarkt in Saint-Tropez en verken lokale ambachten, eten en souvenirs.",
          // Explore the Old Port of Saint-Tropez
          "Explore the Old Port of Saint-Tropez": "Verken de oude haven van Saint-Tropez",
          "1.5 hours": "1,5 uur",
          "After the market, wander around the Old Port, enjoying the cafes and charming atmosphere.": "Na de markt kunt u wandelen rond de oude haven en genieten van de cafés en de charmante sfeer.",
          // Sunday Morning Market in Ramatuelle
          "Sunday Morning Market in Ramatuelle": "Zondagochtendmarkt in Ramatuelle",
          "1 hours": "1 uur",
          "Start your Sunday with the Ramatuelle market, known for its authentic local products and lively atmosphere.": "Begin uw zondag met de Ramatuelle markt, bekend om zijn authentieke lokale producten en levendige sfeer.",
          // Stroll through the Village of Ramatuelle
          "Stroll through the Village of Ramatuelle": "Wandel door het dorp Ramatuelle",
          "Explore the picturesque village of Ramatuelle, with its narrow streets and traditional houses.": "Verken het pittoreske dorp Ramatuelle, met zijn smalle straatjes en traditionele huizen.",
          // Relax on Pampelonne Beach, Ramatuelle
          "Relax on Pampelonne Beach, Ramatuelle": "Ontspan op het strand van Pampelonne, Ramatuelle",
          "-": "-",
          "Spend your afternoon at Pampelonne Beach, famous for its clear waters and beach clubs.": "Breng uw middag door op het strand van Pampelonne, beroemd om zijn heldere water en strandclubs.",
          // Hiking Escalet - Cap Taillat
          "Hiking Escalet - Cap Taillat": "Wandelen Escalet - Cap Taillat",
          "5 hours": "5 uur",
          "Enjoy a scenic hike in Cap Taillat, discovering natural landscapes and quiet beaches.": "Geniet van een schilderachtige wandeling in Cap Taillat en ontdek natuurlijke landschappen en rustige stranden.",
          
          "Wedding Registry": "Huwelijkslijst",
          "deselect": "Deselecteren",
          "select": "Selecteren",
          "t-spritz": "Aperol Spritz",
          "d-spritz": "Een verfrissende Aperol Spritz in een lokale bar voor Aperitivo.",
          "t-gelato": "Gelato om af te koelen",
          "d-gelato": "Geniet van verschillende smaken gelato in Rome.",
          "t-espresso": "Espresso Tour",
          "d-espresso": "Verken en proef espresso van verschillende cafés in Napels.",
          "t-pastry": "Patisserieproeverij",
          "d-pastry": "Proef heerlijke gebakjes van verschillende bakkerijen in Napels.",
          "t-limoncello": "Limoncello Proeverij",
          "d-limoncello": "Proef traditionele Limoncello in Amalfi.",
          "t-amalfi-bus": "Amalfi Kust Buskaartjes",
          "d-amalfi-bus": "Buskaartjes om de schilderachtige Amalfi Kust te verkennen.",
          "t-vatican": "Vaticaanse Musea",
          "d-vatican": "Toegang tot de Vaticaanse Musea en de Sixtijnse Kapel.",
          "t-arnolfo": "Toren van Arnolfo",
          "d-arnolfo": "Toegang tot de toren van Arnolfo in Florence",
          "t-uffizi": "Galleria degli Uffizi",
          "d-uffizi": "Toegang tot de Uffizi Galerij in Florence",
          "t-augusto": "Giardinetti di Augusto",
          "d-augusto": "Toegang tot de Giardinetti di Augusto in Capri",
          "t-monte-solaro": "Monte Solaro",
          "d-monte-solaro": "Kaartje voor de stoeltjeslift van Monte Solaro in Anacapri",
          "t-ferry": "Bijdrage aan onze veerbootkaartjes",
          "d-ferry": "Draag bij aan onze veerbootkaartjes om de eilanden nabij Rome en Napels te verkennen",
          "t-train": "Bijdrage aan onze treinkaartjes",
          "d-train": "Draag bij aan onze treinkaartjes om de steden van Italië te verkennen",
          "t-sunset-prosecco": "Zonsondergang Prosecco",
          "d-sunset-prosecco": "Aperitivo met Prosecco-toast bij zonsondergang in Rome.",
          "t-luxury-dinner": "Romantisch Diner in Rome",
          "d-luxury-dinner": "Draag bij aan een uniek diner in Rome.",
          "t-pizza-naples": "Pizza in Napels",
          "d-pizza-naples": "Naar Napels gaan en een pizza eten is een must!",
          "t-boat-rental": "Bootverhuur",
          "d-boat-rental": "Huur een boot om het eiland Ponza te verkennen.",
          "t-contribution": "Vreije Bijdrage",
          "d-contribution": "Draag bij aan onze huwelijksreis met een vrije bijdrage.",
          "t-selection": "Jouw Selectie",
          "t-vespa": "Vespa huren",
          "d-vespa": "Huur een Vespa om de kust te verkennen.",
          "t-dinner-capri": "Romantisch Diner in Capri",
          "d-dinner-capri": "Draag bij aan een romantisch diner in Capri.",
          "t-dinner-firenze": "Romantisch Diner in Firenze",
          "d-dinner-firenze": "Draag bij aan een romantisch diner in Firenze.",
          "t-info": "Details",
          "d-wedding-registry": "Lieve gasten,\nWe zijn verheugd jullie te verwelkomen op onze bruiloft, ons bewust van het engagement en de reis die velen van jullie zullen ondernemen om bij ons te zijn op deze speciale dag. Jullie aanwezigheid is het meest kostbare geschenk voor ons, en we wensen niets meer.\nAls je echter de wens voelt om te vieren met een extra gebaar, hebben we gedacht aan een manier die kan bijdragen aan onze huwelijksreis. In september zullen we onze huwelijksreis per trein beginnen en reizen door steden als Firenze, Rome en Napels, en langs de Amalfikust, om uiteindelijk terug te keren naar huis. We hebben een lijst met ideeën voorbereid die je kunnen inspireren. Als je besluit deel te nemen, laat dan je naam achter zodat we je persoonlijk kunnen bedanken.\nEr zijn geen verwachtingen, alleen de wens om het geluk van dit moment met jullie te delen. Voor degenen die willen bijdragen, kun je dit doen door een optie uit de lijst te selecteren of met een vrije bijdrage, die kan worden overgemaakt naar onze gezamenlijke rekening.\nDank je wel voor de liefde en steun die je ons altijd hebt getoond."
        },
      },
      it: {
        translation: {
          "We're getting married": "Ci sposiamo",
          "Welcome to the app": "Benvenuto nell'app",
          "RSVP": "RSVP",
          "success-registry": "Grazie mille!\nLa tua selezione è stata inviata con successo!",
          "b-transport": "RSVP",
          "Travel": "Viaggio",
          "l-taxi": "Trasporto",
          "l-diet": "Restrizioni dietetiche",
          "b-add-person": "Aggiungi ospite",
          "Accomodation": "Hotels",
          "Programme": "Programma",
          "To do tips": "Consigli da fare",
          "Contact": "Contatto",
          "Name": "Nome",
          "Email": "Email",
          "Message": "Messaggio",
          "Attendance": "Partecipazione",
          "I'm attending": "Ci sarò!",
          "I'm not attending": "Non ci sarò",
          "Notes": "Note",
          "Submit": "Invia",
          "Cancel": "Annulla",
          "Add": "Aggiungi",
          "Name and Family Name": "Nome e cognome",
          "Email or Phone Number": "E-mail o numero di telefono",
          "Dietary restrictions, I need help with the transport or other notes": "Restrizioni dietetiche, ho bisogno di aiuto con il trasporto o altre note",
          "We're sorry, but we couldn't find your invitation.": "Ci dispiace, ma non siamo riusciti a trovare il tuo invito.",
          "Please try again or contact us.": "Per favore riprova o contattaci.",
          "RSVP Submitted Successfully!": "RSVP inviato con successo!",
          "Open": "Aprire",
          "d-no-gifts": "Nessuna selezione. Per selezionare, fare clic su una delle immagini qui sopra.",
          // Hotels: 
          // La Ferme Ladouceur
          "Nestled near Ramatuelle and Pampelonne Beach, this charming bastide offers a retreat amidst Provence's vineyards.": "Situato vicino a Ramatuelle e alla spiaggia di Pampelonne, questo affascinante bastide offre un rifugio tra i vigneti della Provenza.",
          "From 140 EUR per room, per night": "Da 140 EUR a camera, per notte",
          // Kon Tiki Ramatuelle
          "Beachfront experience on Pampelonne, near Saint Tropez, with unique Tiki Huttes and vibrant beach restaurants nearby.": "Esperienza fronte mare a Pampelonne, vicino a Saint Tropez, con uniche Tiki Huttes e vivaci ristoranti sulla spiaggia nelle vicinanze.",
          "From 150 EUR per room, per night": "Da 150 EUR a camera, per notte",
          // Toison D'or
          "Waterfront resort near Saint Tropez, ideal for outdoor activities, close to Grimaud Castle and St. Tropez Harbor.": "Resort sul lungomare vicino a Saint Tropez, ideale per attività all'aperto, vicino al Castello di Grimaud e al Porto di St. Tropez.",
          // L'Ecurie du Castellas
          "Hillside hotel in Ramatuelle with en-suite rooms, private terraces, and panoramic views, perfect for countryside exploration.": "Hotel in collina a Ramatuelle con camere con bagno, terrazze private e viste panoramiche, perfetto per esplorare la campagna.",
          "From 120 EUR per room, per night": "Da 120 EUR a camera, per notte",
          // La Romarine
          "Nestled between Saint-Tropez and Pampelonne Beaches, offering bedrooms and villas in a lush park and a pool.": "Nidificato tra le spiagge di Saint-Tropez e Pampelonne, offre camere da letto e ville in un lussureggiante parco con piscina.",
          // Hôtel Les Bouis
          "Peaceful retreat atop Ramatuelle hills, close to Pampelonne beaches, with rooms offering sea views, a garden, and a pool": "Tranquillo rifugio sulle colline di Ramatuelle, vicino alle spiagge di Pampelonne, con camere che offrono vista sul mare, un giardino e una piscina",
          "From 200 EUR per room, per night": "Da 200 EUR a camera, per notte",
          // Résidence les sellettes
          "In the middle of a pine forest, a field of olive trees and vineyards. Minutes away from Pampelonne Beach and from Ramatuelle.": "Nel mezzo di una pineta, un campo di ulivi e vigneti. A pochi minuti dalla spiaggia di Pampelonne e da Ramatuelle.",
          "From 100 EUR per room, per night": "Da 100 EUR a camera, per notte",
          // Ramatuelle Tourisme
          "Explore more stays! Check our tourism office list for a variety of local accommodation options.": "Esplora altri soggiorni! Controlla la nostra lista dell'ufficio turistico per una varietà di opzioni di alloggio locali.",
          "": "",
          // AirBnB
          "Explore the diversity of Ramatuelle on Airbnb. Choose from a wide range of properties to suit every taste and budget.": "Esplora la diversità di Ramatuelle su Airbnb. Scegli tra una vasta gamma di proprietà adatte a tutti i gusti e budget.",
          // Contact paragraph
          "If you have any questions, please don't hesitate to reach out to us using the form below, or for urgent matters, feel free to contact us directly. We look forward to hearing from you!": "Se hai domande, non esitare a contattarci utilizzando il modulo qui sotto, o per questioni urgenti, sentiti libero di contattarci direttamente. Non vediamo l'ora di avere tue notizie!",
          // Programme
          "Ceremony": "Cerimonia",
          "Aperó": "Aperitivo",
          "Dinner": "Cena",
          "After-Dinner": "Dopo cena",
          "End": "Fine",
          // To do tips
          // Saturday Morning Market in Saint-Tropez
          "Saturday Morning Market in Saint-Tropez": "Mercato del sabato mattina a Saint-Tropez",
          "2 hours": "2 ore",
          "Begin your day at the vibrant Saturday market in Saint-Tropez, exploring local crafts, foods, and souvenirs.": "Inizia la tua giornata al vivace mercato del sabato a Saint-Tropez, esplorando artigianato locale, cibo e souvenir.",
          // Explore the Old Port of Saint-Tropez
          "Explore the Old Port of Saint-Tropez": "Esplora il vecchio porto di Saint-Tropez",
          "1.5 hours": "1,5 ore",
          "After the market, wander around the Old Port, enjoying the cafes and charming atmosphere.": "Dopo il mercato, girovaga per il vecchio porto, godendoti i caffè e l'atmosfera affascinante.",
          // Sunday Morning Market in Ramatuelle
          "Sunday Morning Market in Ramatuelle": "Mercato della domenica mattina a Ramatuelle",
          "1 hours": "1 ora",
          "Start your Sunday with the Ramatuelle market, known for its authentic local products and lively atmosphere.": "Inizia la tua domenica con il mercato di Ramatuelle, noto per i suoi autentici prodotti locali e l'atmosfera vivace.",
          // Stroll through the Village of Ramatuelle
          "Stroll through the Village of Ramatuelle": "Passeggiata attraverso il villaggio di Ramatuelle",
          "Explore the picturesque village of Ramatuelle, with its narrow streets and traditional houses.": "Esplora il pittoresco villaggio di Ramatuelle, con le sue stradine e le case tradizionali.",
          // Relax on Pampelonne Beach, Ramatuelle
          "Relax on Pampelonne Beach, Ramatuelle": "Rilassati sulla spiaggia di Pampelonne, Ramatuelle",
          "-": "-",
          "Spend your afternoon at Pampelonne Beach, famous for its clear waters and beach clubs.": "Trascorri il pomeriggio sulla spiaggia di Pampelonne, famosa per le sue acque cristalline e i club sulla spiaggia.",
          // Hiking Escalet - Cap Taillat
          "Hiking Escalet - Cap Taillat": "Escursione a Escalet - Cap Taillat",
          "5 hours": "5 ore",
          "Enjoy a scenic hike in Cap Taillat, discovering natural landscapes and quiet beaches.": "Goditi un'escursione panoramica a Cap Taillat, scoprendo paesaggi naturali e spiagge tranquille.",
          
          "Wedding Registry": "Lista nozze",
          "deselect": "Deseleziona",
          "select": "Seleziona",
          "t-spritz": "Aperol Spritz",
          "d-spritz": "Un rinfrescante Aperol Spritz in un bar locale per l'aperitivo.",
          "t-gelato": "Gelato per rinfrescarsi",
          "d-gelato": "Assapora diversi gusti di gelato a Roma.",
          "t-espresso": "Tour dell'Espresso",
          "d-espresso": "Esplora e assaggia l'espresso da vari caffè a Napoli.",
          "t-pastry": "Degustazione di pasticcini",
          "d-pastry": "Assaggia deliziosi pasticcini da diverse pasticcerie a Napoli.",
          "t-limoncello": "Degustazione di Limoncello",
          "d-limoncello": "Assapora il tradizionale Limoncello ad Amalfi.",
          "t-amalfi-bus": "Biglietti autobus per la Costiera Amalfitana",
          "d-amalfi-bus": "Biglietti autobus per esplorare la pittoresca Costiera Amalfitana.",
          "t-vatican": "Musei Vaticani",
          "d-vatican": "Ingresso ai Musei Vaticani e alla Cappella Sistina.",
          "t-arnolfo": "Torre di Arnolfo",
          "d-arnolfo": "Ingresso alla torre di Arnolfo a Firenze",
          "t-uffizi": "Galleria degli Uffizi",
          "d-uffizi": "Ingresso alla Galleria degli Uffizi a Firenze",
          "t-augusto": "Giardinetti di Augusto",
          "d-augusto": "Ingresso ai Giardinetti di Augusto a Capri",
          "t-monte-solaro": "Monte Solaro",
          "d-monte-solaro": "Biglietto per la seggiovia del Monte Solaro ad Anacapri",
          "t-ferry": "Contributo ai nostri biglietti del traghetto",
          "d-ferry": "Contribuisci ai nostri biglietti del traghetto per esplorare le isole vicino a Roma e Napoli",
          "t-train": "Contributo ai nostri biglietti del treno",
          "d-train": "Contribuisci ai nostri biglietti del treno per esplorare le città d'Italia",
          "t-sunset-prosecco": "Prosecco al tramonto",
          "d-sunset-prosecco": "Brindisi al tramonto in giro per l'Italia.",
          "t-luxury-dinner": "Cena romantica a Roma",
          "d-luxury-dinner": "Contribuisci a una cena unica a Roma.",
          "t-pizza-naples": "Pizza a Napoli",
          "d-pizza-naples": "Andare a Napoli e mangiare una pizza è un must!",
          "t-boat-rental": "Noleggio barca",
          "d-boat-rental": "Noleggia una barca per esplorare l'isola di Ponza.",
          "t-contribution": "Contributo Libero",
          "d-contribution": "Contributo libero per la nostra luna di miele.",
          "t-selection": "La tua selezione", 
          "t-vespa": "Noleggio Vespa",
          "d-vespa": "Noleggia una Vespa per esplorare la costa.",
          "t-dinner-capri": "Cena romantica a Capri",
          "d-dinner-capri":  "Contribuisci a una cena romantica a Capri.",
          "t-dinner-firenze": "Cena romantica a Firenze",
          "d-dinner-firenze": "Contribuisci a una cena romantica a Firenze.",
          "t-info": "Dettagli",
          "d-wedding-registry": "Cari ospiti,\nCon grande gioia vi accoglieremo al nostro matrimonio, consapevoli dell'impegno e del viaggio che molti di voi affronteranno per essere con noi in questo giorno speciale. La vostra presenza è, per noi, il dono più prezioso, e non desideriamo nulla oltre.\nTuttavia, se sentite il desiderio di celebrare con un gesto ulteriore, abbiamo pensato a un modo che possa contribuire al nostro viaggio di nozze. A settembre partiremo per il viaggio di nozze in treno, passando da Firenze, Roma e Napoli e la Costa Amalfitana, per poi tornare a casa. Abbiamo preparato una lista di idee che potrebbero ispirarvi. Se decidete di partecipare, vi invitiamo a lasciare il vostro nome, affinché possiamo ringraziarvi personalmente.\nNon vi è alcuna aspettativa, ma solo il desiderio di condividere con voi la felicità di questo momento. Per chi desidera contribuire, potete farlo selezionando un'opzione dalla lista o con un contributo libero, che potrà essere trasferito sul nostro conto condiviso.\nGrazie di cuore per l'amore e il sostegno che ci avete sempre dimostrato",
        },
      }
    },
    // fallback language is english.
    fallbackLng: 'en',
    detection: {
      order: ['queryString', 'cookie', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;
