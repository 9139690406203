import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Button from '@mui/material/Button';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Some CSS
          borderColor: 'white',
          color: 'white',
          '&:active': {
            borderColor: "#91CFCA",
            color: 'white',
          },
          '&:hover': {
            borderColor: "#91CFCA",
            color: 'white',
          },
          '&:focus': {
            borderColor: "#91CFCA",
            color: 'black',
          }
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:focus-within $notchedOutline': {
            borderColor: '#91CFCA', // Change this to your desired focus color
          },
          '&:hover $notchedOutline': {
            borderColor: 'white', 
          },
          '&.Mui-disabled $notchedOutline': {
            borderColor: 'white', // Your desired color for disabled state
          },
          "&.Mui-active": {
            "& $notchedOutline": {
              "borderColor": "#91CFCA"
            }
          },
          "&.Mui-focused": {
            "& $notchedOutline": {
              "borderColor": "#91CFCA"
            }
          },
          // Some CSS
          borderColor: 'white',
          color: 'white',
          '&:active': {
            borderColor: "#91CFCA",
            color: 'white',
          },
          '&:hover': {
            borderColor: "#91CFCA",
            color: 'white',
          }
        },
        notchedOutline: {
          borderColor: 'white', // Default border color
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // Some CSS
          borderColor: 'white',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          color: 'black',
          '&:active': {
            borderColor: "#91CFCA",
            color: 'black',
          },
          '&:hover': {
            borderColor: "#91CFCA",
            color: 'black',
          },
          '&:focus': {
            borderColor: "#91CFCA",
            color: 'black',
          }
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: 15,
          width: '100px',
          backgroundColor: "#91CFCA",
          padding: "10px 15px",
          fontSize: "15px",
          '&:active': {
            backgroundColor: "#91CFCA", // Replace with your desired active color
          },
          '&:hover': {
            backgroundColor: "#91CFCA", // Replace with your desired active color
          },
          '&:focus': {
            backgroundColor: "#91CFCA", // Replace with your desired active color
          }
        },
      },
    },
  },
});

function LoadingPage() {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false); // State to control button visibility

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true); // Show button after 5 seconds
    }, 5000); // Set the timer for 5 seconds

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);

  const handleSignOutClick = () => {
    navigate('/sign-out');
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ClipLoader size={45} color={"#8DBB7D"} />
        <div style={{ height: '40px' }} />
        {showButton && ( // Render button only if showButton is true
          <Button
            type="submit"
            variant="contained"
            onClick={handleSignOutClick}
          >
            Reset
          </Button>
        )}
      </ThemeProvider>
    </div>
  );
}

export default LoadingPage;
