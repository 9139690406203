// SignInComponent.js
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ClipLoader from "react-spinners/ClipLoader";

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Some CSS
          borderColor: 'white',
          color: 'white',
          '&:active': {
            borderColor: "#91CFCA",
            color: 'white',
          },
          '&:hover': {
            borderColor: "#91CFCA",
            color: 'white',
          },
          '&:focus': {
            borderColor: "#91CFCA",
            color: 'black',
          }
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:focus-within $notchedOutline': {
            borderColor: '#91CFCA', // Change this to your desired focus color
          },
          '&:hover $notchedOutline': {
            borderColor: 'white', 
          },
          '&.Mui-disabled $notchedOutline': {
            borderColor: 'white', // Your desired color for disabled state
          },
          "&.Mui-active": {
            "& $notchedOutline": {
              "borderColor": "#91CFCA"
            }
          },
          "&.Mui-focused": {
            "& $notchedOutline": {
              "borderColor": "#91CFCA"
            }
          },
          // Some CSS
          borderColor: 'white',
          color: 'white',
          '&:active': {
            borderColor: "#91CFCA",
            color: 'white',
          },
          '&:hover': {
            borderColor: "#91CFCA",
            color: 'white',
          }
        },
        notchedOutline: {
          borderColor: 'white', // Default border color
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // Some CSS
          borderColor: 'white',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          color: 'black',
          '&:active': {
            borderColor: "#91CFCA",
            color: 'black',
          },
          '&:hover': {
            borderColor: "#91CFCA",
            color: 'black',
          },
          '&:focus': {
            borderColor: "#91CFCA",
            color: 'black',
          }
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: 15,
          backgroundColor: "#91CFCA",
          padding: "10px 15px",
          fontSize: "15px",
          '&:active': {
            backgroundColor: "#91CFCA", // Replace with your desired active color
          },
          '&:hover': {
            backgroundColor: "#91CFCA", // Replace with your desired active color
          },
          '&:focus': {
            backgroundColor: "#91CFCA", // Replace with your desired active color
          }
        },
      },
    },
  },
});

const SignInComponent = ({ onChange, signIn, formState, error }) => {
  const [logginIn, setLogginIn] = useState(false); // State to indicate if the user is logging in or not
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  const navigate = useNavigate(); // Initialize navigate

  // Function to handle the form submit event
  const handleSubmit = async (event) => {
    setLogginIn(true); // Set the logginIn state to true
    event.preventDefault(); // Prevent the default form submit actionset
    await signIn(); // Call the signIn function passed as a prop
      // Optionally reset the logginInState here if needed
    console.log('Logging in...')
    await sleep(500); // Wait for 200 milliseconds
    console.log('CheckUser in...')
    
    if (!error) {
      console.log('Logged in!')
      setLogginIn(false);
      navigate('/'); // Navigate to the main page
    } else {
      console.log('Error logging in!')
      setLogginIn(false);
    }
  }

  return (
    // Add the onSubmit event handler to the form
    <ThemeProvider theme={theme}> 
      <form onSubmit={handleSubmit} style={{ paddingTop: '25px' }}>
      <TextField
        variant="outlined"
        label="Password"
        name="password"
        type="password"
        onChange={onChange}
        placeholder="Enter your password"
        value={formState.password}
        fullWidth
      />
        <div style={{ height: '25px' }} />
          <Button
            type="submit" // Make the button of type submit to submit the form on click
            variant="contained"
            disabled={formState?.password?.length < 8}
            startIcon={logginIn ?  <ClipLoader size={'15px'} color='white' /> : null}
            fullWidth
          >
            Sign In
          </Button>
        {error && <div className="errorBox">{error}</div>}
    </form>
    
    </ThemeProvider>
  );
};

SignInComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    password: PropTypes.string,
  }).isRequired,
  error: PropTypes.string,
};

SignInComponent.defaultProps = {
  error: '',
};

export default SignInComponent;
