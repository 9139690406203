import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const SignOut = ({ onSignOut }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const signOut = async () => {
      try {
        await Auth.signOut();
        onSignOut(); // Call the onSignOut function passed from the parent component
        navigate('/sign-in');
      } catch (error) {
        console.error('Error signing out: ', error);
      }
    };

    signOut();
  }, [navigate, onSignOut]);

  return null; // This component doesn't render anything
};

export default SignOut;